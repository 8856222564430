@keyframes periodicfading {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.bull-loader-wrapper {
  width: 100%;
  height: 100%;
  max-height: 99vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
  border-radius: 5px;
  right: 0;
  animation-name: periodicfading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0s;
  img {
    height: 250px;
  }
}
