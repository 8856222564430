// Mixins for mobile and other devices
@mixin for-mobile-only {
  @media (max-width: 767px) {
    @content;
  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

@mixin for-large-screen {
  @media (min-width: 767px) {
    @content;
  }
}

@mixin for-extra-screen {
  @media (max-width: 1679px) {
    @content;
  }
}

@mixin for-medium-large-screen {
  @media (max-width: 1460px) {
    @content;
  }
}

@mixin for-large-laptop {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin for-medium-laptop {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin for-laptop {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin for-small-laptop {
  @media (max-width: 1070px) {
    @content;
  }
}
@mixin for-large-tablets {
  @media (max-width: 999px) {
    @content;
  }
}
@mixin for-ipad-pro {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin for-ipad {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin for-small-devices {
  @media (max-width: 498px) {
    @content;
  }
}

@import "./sidebar";
@import "./main";
@import "./table";
@import "./pagination";
@import "./forms";
@import "./animations";

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: rgba(47, 79, 79, 0.91);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(2, 195, 132, 1);
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
  background: rgba(0, 0, 0, 0.75) !important;
  z-index: 21 !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}


body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #313535; /* or any color that matches your background image */
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #313535;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
}

body.loaded::before {
  opacity: 1;
}