.table__data {
  background-color: #0e1b38;

  &__mobile {
    @include for-mobile-only {
      // height: 210px;
      margin-left: 15px !important;
    }
    @include for-ipad {
      // height: 210px;
      margin-left: 70px;
    }
    @include for-ipad-pro {
      height: 240px;
      margin-left: 70px;
    }
  }

  td {
    border-bottom-color: #1d1d1d;
  }

  &__mb60 {
    margin-bottom: 60px;
    @include for-mobile-only {
      margin-bottom: 10px;
    }
    @include for-ipad {
      margin-bottom: 10px;
    }
    @include for-ipad-pro {
      margin-bottom: 10px;
    }
  }

  tr {
    cursor: pointer;

    .hidden {
      display: none;

      @include for-mobile-only {
        display: flex;
      }
    }
  }

  &__coins {
    color: white;
    font-weight: 900;
  }
}

.btn-success {
  color: #02ff00;
  min-inline-size: max-content;
  font-weight: 800;
  @include for-mobile-only {
    font-size: 10px;
  }
}

.btn-error {
  min-inline-size: max-content;
  color: red;
  font-weight: 800;

  @include for-mobile-only {
    font-size: 10px;
  }
}

.btn-closed {
  // min-inline-size: max-content;
  color: #d8d8d8;
  font-weight: 800;

  @include for-mobile-only {
    font-size: 10px;
  }
}

.btn-pending {
  min-inline-size: max-content;
  color: orange;
  font-weight: 800;
}

.image__container {
  background-color: #1d1d1d34;
  width: 80px;
  height: 80px;
  margin-left: 30px;
  border-radius: 10px;

  @include for-extra-screen {
    width: 69px;
    height: 71px;
  }

  &__padding {
    width: 50px;
    height: 50px;
    overflow: hidden;
    margin: 15px;
    border-radius: 70px;

    @include for-mobile-only {
      margin: 13px;
    }
  }

  &__width {
    width: 60%;
    height: 100px;
    float: left;
    transform: rotate(30deg);
    overflow: hidden;
    margin-top: -25px;
    margin-left: -10px;
  }

  &__giveCoin {
    background-size: 80px;
    background-position: -15px -5px;
    background-repeat: no-repeat;
    height: 120%;
    transform: rotate(-30deg);
    width: 200%;
  }

  &__right {
    width: 60%;
    height: 150%;
    float: right;
    transform: rotate(30deg);
    overflow: hidden;
    margin-top: -20px;
    margin-right: -12px;
    border-right: 2px solid #0c0c15;
  }

  &__img {
    background-size: 80px;
    background-position: -20px 5px;
    background-repeat: no-repeat;
    height: 100%;
    transform: rotate(-30deg);
    width: 200%;
    margin-top: 0px;
    margin-left: -30px;
  }
}

.close-icon {
  font-size: 25px;
  padding-right: 10px;
}
