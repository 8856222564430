@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.206/distr/fira_code.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

.react-loading-skeleton {
  background-color: #2d2d2d !important;
}

/* The shimmer/animation on top of the skeleton */
.react-loading-skeleton::after {
  background-image: linear-gradient(90deg,
      #2d2d2d 20%,
      #f7931a 47%,
      #2d2d2d 60%) !important;
}