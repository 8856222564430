.form-container {
  width: 100%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 10px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
}
@media (max-width: 778px) {
  .form-container {
    grid-template-columns: 1fr;
  }
}

.kep-login-facebook {
  //max-width: 263px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 7px !important;
  button {
    line-height: 0.15;
  }
  padding: calc(0.14435vw + 8.38843px) calc(0.14435vw + 23.38843px) !important;
  @include for-mobile-only {
    padding: calc(0.14435vw + 8.38843px) calc(5.14435vw + 21.38843px) !important;
  }
}

.google-button button {
  background: #f85749 !important;
  color: white !important;
  width: 100%;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  border-radius: 7px !important;
  opacity: 1 !important;
}

.google-button span {
  padding: 6px 6px 6px 6px;
  font-size: 16px;
}

.google-button > button > div {
  background: #f85749 !important;
  padding: 10px;
}

@include for-mobile-only {
  .google-button > button > div {
    padding: 7px !important;
  }
}

.close-btn {
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 1.5rem;
  z-index: 1;
  color: #fff;
  cursor: pointer;
}

.form-hr {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
  margin-bottom: 20px;
}

.form-hr > span {
  background: #f1f1f1;
  padding: 0 10px;
}

.form-content-left {
  position: relative;
  background-color: #292929 !important;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 768px) {
  .form-content-left {
    background-size: cover;
  }
}
@media (max-width: 540px) {
  .form-content-left {
    background-size: 602px 147px;
  }

  .form-content-left > div > .form-content-left-text {
    margin-top: 52px !important;
    margin-right: 52px !important;
  }
}

@media (max-width: 414px) {
  .form-content-left {
    background-size: 602px 147px;
  }

  .form-content-left > div > .form-content-left-text {
    margin-top: 61px !important;
    margin-right: 103px !important;
  }
}

.form-content-left-logo {
  width: 180% !important;
  height: auto;
  display: flex;
  max-width: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  left: -40%;
}

@media (max-width: 768px) {
  .form-content-left-logo {
    display: flex !important;
    position: absolute;
    top: 0;
    bottom: 0px;
    margin-top: auto;
    left: -150px;
    width: 150% !important;
    opacity: 1;
  }
}

@media (max-width: 411px) {
  .form-content-left {
    background-size: 602px 146px;
  }
}

@media (max-width: 375px) {
  .form-content-left {
    background-size: cover;
  }

  .form-content-left > div > .form-content-left-text {
    margin-top: 86px !important;
    margin-right: 68px !important;
  }
}

@media (max-width: 360px) {
  .form-content-left {
    background-size: 478px 99px;
  }
  .form-content-left > div > .form-content-left-text {
    margin-top: 37px !important;
    margin-right: 99px !important;
  }
}

@media (max-width: 320px) {
  .form-content-left {
    height: 109px !important;
    background-size: 475px 108px;
  }
  .form {
    padding-bottom: 76px !important;
  }

  .form-content-left > div > .form-content-left-text {
    margin-top: 42px !important;
  }
}

.form-content-left > div > .form-content-left-text {
  width: 83%;
  display: flex;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: 50%;
  margin-bottom: auto;
}
@media (max-width: 767px) {
  .form-content-left {
    height: auto;
    background-size: cover;
  }

  .form-content-left > div > .form-content-left-text {
    width: 39%;
    right: 0;

    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: 50%;
    margin-bottom: auto;
    margin-top: auto;

    right: 0;

    margin-top: auto !important;
  }
}

.form-link {
  position: absolute;
  bottom: 0;
  margin-bottom: 38px;
  display: flex;
  justify-content: center;
  width: 100%;

  @include for-mobile-only {
    p {
      font-size: 13px;
    }
  }
}
.form-forgot-password {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-forgot-password > a {
  text-decoration: none;
  color: #5c5b71;
}

.form-link > p > a {
  text-decoration: none;
  color: #059f6a;
  font-weight: 900;
}

.form-icon {
  color: #059f6a;
  font-size: 60px;
}
.form-head {
  padding: 20px;
  text-align: center;
  background-color: #059f6a;
  color: white;
  position: absolute;
  width: 100%;
}

.form-body {
  border-radius: 22px;
  padding: 35px;
  border-color: #059f6a;
  border-radius: 20px;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 12px 10px lightgrey;
}

.form-img {
  width: 130%;
  max-width: 1072px;
  top: 656px;
  box-sizing: 10px 10px 10px;
  /* box-shadow: 10px 10px 10px black; */
  /* position: absolute; */
  /* top: 50%; */
  position: absolute;
  right: 163px;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  bottom: 0;
}
@media (max-width: 778px) {
  .form-img {
    width: 50%;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    right: 81px;
    bottom: -81px;
  }
}

.form-img-2 {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-success {
  text-align: center;
  font-size: 24px;
  margin-top: 80px;
  color: #fff;
}

.btn-more {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  box-shadow: 0 0 0 2px #059f6a;
  background: #23ac79;
  background-position: 0;
  transition: 0.5s;
  font-size: 0.9rem;
  line-height: 1;
  border-radius: 0.3rem;
  color: #fff;
  background-size: 200% auto;
  border-radius: 7px !important;
}

.form-content-right {
  position: relative;
  background: #f1f1f1;
  overflow: hidden;

  @media (max-width: 768px) {
    z-index: 100;
  }
}

.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 33%;
  margin-left: 33%;
}
.form-mobile-logo {
  position: absolute;
  top: 0;
  margin-top: 50px;
  margin-left: 10%;
  margin-right: 10%;
}

.form h2 {
  color: black;
  padding-bottom: 35px;
  font-weight: 700;
}

.form-input:focus {
  outline: none !important;
  border: 1px solid #368054;
  box-shadow: 0 0 10px #719ece;
}

.form-input {
  outline: none !important;
  border: 1px solid lightgrey;
}

.form-inputs > label {
  color: #5c5b71;
}

@media (max-width: 778px) {
  .form {
    padding-bottom: 30px;
    padding-top: 14px;
    margin-right: 7%;
    margin-left: 7%;
  }
}

.form h1 {
  font-size: 1rem;
  text-align: start;
  width: 80%;
  margin-bottom: 1rem;
  color: #fff;
}

.form-inputs {
  margin-bottom: 0.5rem;
}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
}

.form-label {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 6px;
  color: #fff;
  @include for-mobile-only {
    display: none;
  }
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border-radius: 7px;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input-btn {
  width: 80%;
  height: 50px;
  margin-top: 10px;
  border-radius: 2px;
  background: linear-gradient(
    90deg,
    rgb(39, 176, 255) 0%,
    rgb(0, 232, 236) 100%
  );
  outline: none;
  border: none;
  color: #fff;
  font-size: 1rem;
}

.form-input-btn:hover {
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgb(39, 143, 255) 0%,
    rgb(12, 99, 250) 100%
  );
  transition: all 0.4s ease-out;
}

.form-input-login {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #fff;
  width: 80%;
  text-align: center;
}

.form-input-login a {
  text-decoration: none;
  color: #27cdff;
  font-weight: 600;
}

.form-success-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 5%;
  margin-top: 11%;
}

.form-success-info > div {
  color: white;
  margin-bottom: 20px;
}

.form-success-image {
  text-align: center;
  margin-top: 3%;
}

// UPDATE EMAIL MODAL

.update__clientEmail {
  color: white;
  .p-4 {
    padding: 0.3rem !important;
  }
  input {
    color: black;
  }

  h6 {
    width: 100%;
    color: #ffffff;
    position: absolute;
    opacity: 0.2;
    font-size: 4.625rem;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    margin: 0 auto;
    display: block;
    left: 11%;
    transform: translate(-50%);
    top: 71px;
    text-transform: uppercase;
  }

  h2 {
    width: 100%;
    color: #ffffff;
    position: absolute;
    font-size: 2.625rem;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    margin: 0 auto;
    display: block;
    left: 11%;
    transform: translate(-50%);
    top: 150px;
    text-transform: uppercase;
  }

  .btn-submit {
    width: 100%;
    > button {
      width: 100%;
      padding: 10px;
      transition: 0.5s;
      background-image: linear-gradient(
        40deg,
        #068e5e 20%,
        #00d893 51%,
        #068e5e 90%
      );
      background-position: left center;
      background-size: 200% auto;
      border: 0;
      color: #ffffff;
    }
  }
}
