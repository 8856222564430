$sidebar-bg-color: #292929;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;
//TOGO GZ reenable this
//@import "~react-pro-sidebar/dist/scss/styles.scss";
@mixin for-mobile-only {
  @media (max-width: 768px) {
    @content; // This allows the mixin to accept a content block
  }
}
body {
  margin: 0;
  height: 100vh;
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
}

#root {
  height: 100vh;
  overflow: hidden;
}

.app {
  min-height: 100vh;
  display: flex;
  position: relative;

  .pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;

    @include for-mobile-only() {
      padding-left: 0;
      padding-right: 0;
      font-size: 19px;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      padding-top: 10px;
    }
  }

  .pagination li {
    display: inline-block;
    margin-right: 15px;

    @include for-mobile-only() {
      margin-right: 8px;
    }
  }

  .btn-toggle {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: #292929;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    display: none;
    margin-left: 10px;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      border-radius: 5px;
      width: 100%;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: 1200px) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.displayMobile {
  display: none !important;

  @include for-mobile-only() {
    display: block !important;
  }
}

.displayDesktop {
  display: block !important;
  @include for-mobile-only() {
    display: none !important;
  }
}

.toggleSpace {
  @include for-mobile-only {
    font-size: 27px;
  }
}

.logout {
  width: 93% !important;
}

.wrap {
  display: none !important;

  @include for-mobile-only() {
    display: block !important;
  }
}

.user-menu-link {
  display: flex;
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 6px 16px;

  &:hover {
    background-color: #1d1d1d;
    color: white;
  }
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  display: none;
  z-index: 99999;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  @include for-mobile-only() {
    flex-grow: unset;
  }
}

.close-icon {
  display: none !important;
  bottom: 0;
  top: 50% !important;
  bottom: 50% !important;
  top: 30px;
  z-index: 9999;
  margin-left: 4px;
  position: absolute;
  background: #292929;
  color: white;
  font-size: 14px;
  margin-right: 19px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  top: 36%;
  right: -69px;
  height: 50px;
  svg {
    font-size: 25px;
  }

  @include for-mobile-only {
    display: block !important;
  }
}
