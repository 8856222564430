.main {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}


.information-container.wrapper {
  margin: 0px !important;
  padding: 0px !important;
}

.swagger-ui .info .title {
  height: 110px;
  padding-top: 12px;
  color: #000000 !important;
}

.swagger-ui .info li,
.swagger-ui .info p,
.swagger-ui .info table {
  color: #000000 !important;
}


.swagger-ui .parameters-col_description .markdown p,
.swagger-ui .parameters-col_description .renderedMarkdown p {
  font-size: 22px;
  color: black !important;
}


.swagger-ui .auth-container input[type=password],
.swagger-ui .auth-container input[type=text] {
  min-width: 100% !important;
  font-size: 15px !important;
}

.swagger-ui .dialog-ux .backdrop-ux {
  background: rgb(255 255 255 / 80%) !important;
}

.swagger-ui .model-box-control:focus,
.swagger-ui .models-control:focus,
.swagger-ui .opblock-summary-control:focus {
  outline: #000 !important;
}


.swagger-ui .dialog-ux .modal-ux {
  box-shadow: 0 10px 30px 0 rgb(255 255 255 / 20%) !important;
}

.swagger-ui .parameters-col_description input {
  font-size: 14px !important;
}

.swagger-ui .parameter__type {
  font-size: 10px !important;
  padding: 0px 0 !important;
  height: 15px;
}

.swagger-ui .scheme-container {
  background: #eeeeee !important;
  box-shadow: none !important;
  margin: 0 0 20px !important;
  padding: 15px 0 !important;
  border: none !important;
  border-radius: 9px !important;
}


.rc-slider {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.swagger-ui .wrapper {
  max-width: 100% !important;
}


.mainBGWhite {
  background-image: url('/images/bglandscape-norm.png') !important;
  height: 100vh !important;
  width: 100vw !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: absolute !important;
  left: 13% !important;
  top: 0px !important;
}

.mainBGWhiteMob {
  background-image: url('/images/bglandscape-norm.png') !important;
  height: 100vh !important;
  width: 100vw !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: absolute !important;
  left: 0% !important;
  top: 0px !important;
}

.mainBG {
  background-image: url('/images/bglandscape-tsp.png') !important;
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0px;
  top: 0px;
}

hgroup.main {
  height: max-content;
  background: none;
  margin-bottom: 12px;
}

.swagger-ui {
  filter: invert(88%) hue-rotate(180deg);
}

.swagger-ui .highlight-code {
  filter: invert(100%) hue-rotate(180deg);
}

// TOP BAR
.top-bar-container {
  position: fixed;
  height: 48px;
  height: auto;
  width: 100%;
  position: relative;

  &__img {
    width: 19px;

    @include for-mobile-only() {
      width: 30px;
    }
  }

  &__btnContainer {
    svg {
      width: 10px;
      color: #25b07b !important;

      @include for-mobile-only() {
        width: 15px;
        color: #25b07b !important;
      }
    }

    @include for-mobile-only() {
      position: fixed;
      height: 63px;
      bottom: 0;
      justify-content: space-around;
      width: 100%;
      background: #292929;

      .ml-4 {
        margin-left: 0;
      }
    }
  }

  .btnColor {
    color: #25b07b;
  }

  &__btn {
    // border: 1px solid #3b3b3b;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s;

    &:nth-child(2) {
      color: #25b07b;
      margin-left: 0.5rem;

      @include for-mobile-only() {
        margin-left: 0rem;
        padding: 19px 76px;
        color: #25b07b !important;
      }
    }

    &:nth-child(3) {
      color: #25b07b;
      margin-left: 0.5rem;

      @include for-mobile-only() {
        margin-left: -1rem;
      }
    }

    p {
      font-size: 20px;
    }

    @include for-mobile-only() {
      padding: 0px;

      width: 100%;
    }

    &:hover {
      background-color: #292929;
    }
  }

  .search-auto {
    @include for-mobile-only() {
      width: 195px;
    }
  }

  .darkModeSide {
    .text-white {
      color: #5c5b71 !important;
    }
  }

  >button {
    background: white;
  }

  @include for-medium-laptop {
    font-size: 13px;
  }

  @include for-mobile-only {
    padding: 0px;
    background: #292929;
    position: fixed;
    z-index: 999;
    color: white;
    height: 63px;

    svg {
      color: white;
    }
  }
}

// DROPDOWN

$nav-link-color: #666666;
$bg-color: #f3f5f4;
$navbar-color: #ffffff;
$border-gray: #e0e0e0;
$hover-gray: #f5f5f5;
$active-gray: #ebebeb;

$at-blue-light: #00afe9;
$at-blue-dark: #006ab8;

$red: #f44336;

@mixin border-radius($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.user-menu-wrap {
  position: relative;
  z-index: 999;

  ul {
    list-style: none;
  }
}

.menu-container {
  z-index: inherit;
}

.user-menu {
  position: absolute;
  right: 0px;
  background: #292929;
  color: white;
  width: 256px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 4px;
  padding-bottom: 4px;
  top: 14px;
  border-radius: 6px !important;

  .profile-highlight {
    display: flex;
    border-bottom: 1px solid $border-gray;
    padding: 12px 16px;
    margin-bottom: 6px;
    justify-content: center;

    img {
      width: 48px;
      height: 48px;
      border-radius: 25px;
      object-fit: cover;
    }

    .details {
      display: flex;
      flex-direction: column;
      margin: auto 12px;

      #profile-name {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        font-style: italic;
      }

      #profile-footer {
        font-weight: 300;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }

  .user-menu-link {
    display: flex;
    text-decoration: none;
    color: white;
    font-weight: 400;
    font-size: 15px !important;
    padding: 12px 16px;

    img {
      color: white !important;
    }

    div {
      margin: auto 10px;
    }

    &:hover {
      background-color: #1d1d1d;
      color: white;
    }
  }

  &:before {
    position: absolute;
    top: -16px;
    left: 198px;
    display: inline-block;
    content: "";
    border: 8px solid transparent;
    border-bottom-color: $border-gray;
  }

  &:after {
    position: absolute;
    top: -14px;
    left: 198px;
    display: inline-block;
    content: "";
    border: 7px solid transparent;
    border-bottom-color: #ffffff;
  }

  .footer {
    padding-top: 6px;
    margin-top: 6px;

    .user-menu-link {
      font-size: 13px;
    }
  }
}

// TRANSACTION HISTORY COMPONENT

// Main Area //


.customUsdIcon {
  background-color: #0cd56f;
  padding: 5px 9px 5px 9px;
  border-radius: 100%;
}

.extraMn {
  z-index: 0;
  position: relative;
}

.main-area {
  margin-top: 30px;
  height: auto;

  .select {
    background: #292929;
  }

  &__search {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin-left: 30px;
    background: #292929;
  }

  .close-icon {
    font-size: 25px;
    padding-right: 10px;
  }

  .modal-icon {
    position: absolute;
    /* bottom: 0; */
    transition: 0.5s;
    background-image: linear-gradient(40deg,
        #068e5e 20%,
        #00d893 51%,
        #068e5e 90%);
    background-position: left center;
    background-size: 200% auto;
    border: 0;
    color: #ffffff;
    max-width: 152px;
    border-radius: 100px;
    /* left: inherit; */
    right: 0;
    bottom: 0;
    margin-bottom: 31px;
    margin-right: 21px;
    padding: 22px;
  }

  .box {
    background-color: #292929;
  }

  .border-radius {
    border-radius: 10px;
  }

  &__svg {
    padding-left: 13px;
    color: lightgrey;
    background: #292929;
    font-size: 20px;
  }

  &__filter {
    border-left: 1px solid lightgrey;
    padding-left: 20px;
  }

  &__icon {
    color: gray;
    position: absolute;
    top: 11px;
    font-size: 16px;
  }

  @include for-mobile-only {
    padding-left: 13px;
    margin-top: 15%;
  }

  @include for-small-devices {
    margin-top: 24%;
  }
}

// Dashboard Component

.dashboard-container {
  display: grid;
  grid-template-columns: 70% 30%;
  height: 76vh;

  @include for-ipad-pro {
    height: auto;
  }

  @include for-laptop {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fit, 1fr);
  }
}


.customCont {
  background-color: #2d2d2d;
  border-radius: 8px;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 20%) !important;
}